import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '../app.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  appointmentName = 'Concepto'
  loading:boolean = false
  constructor(private router: Router) {
    this.router.events.subscribe(value => {
      if (this.router.url.toString() === '/nipt') {
        this.appointmentName = 'NIPT';
      } else if (this.router.url.toString().includes('/nipt')) {
        this.appointmentName = 'NIPT';
      } else {
        this.appointmentName = 'Concepto';
      }
    });
  }

  ngOnInit(): void {
  }

  socialMedia(type) {
    if (type === 'instagram') {
      window.open('https://instagram.com/conceptodiagnostics?igshid=YmMyMTA2M2Y=');
    }
    if (type === 'facebook') {
      window.open('https://www.facebook.com/conceptoclininc/');
    }
    if (type === 'twitter') {
      window.open('https://twitter.com/ConceptoTweets/status/1538866372985475073?s=20&t=3cJaciNOTy2FvaFaBxjsjg');
    }
  }

  quickLink(type) {
    this.loading = true
    window.scrollTo(0, 0);
    // if(type === 'Find Clinic'){
    //   document.location.href = `${AppConstants.BASE_URL_DIAGNOSTICS}clinic-finder`
    // }
    setTimeout(() => { 
      this.loading = false     
      if (type === 'blog') {
        this.router.navigateByUrl('blog')
      }
      if (type === 'pcr') {
        this.router.navigateByUrl('diagnostics/book-pcr-travel-test')
      }
      if (type === 'antibody') {
        this.router.navigateByUrl('diagnostics/book-antibody-test')
      }
      if (type === 'antigen') {
        this.router.navigateByUrl('diagnostics/book-antigen-test')
      }
      if (type === 'test profile') {
        this.router.navigateByUrl('diagnostics/concepto-test-profiles')
      }
      if (type === 'fit to fly') {
        this.router.navigateByUrl('diagnostics/fit-to-fly')
      }
      if (type === 'corporate testing') {
        this.router.navigateByUrl('diagnostics/corporate-testing')
      }
      if (type === 'FAQ') {
        this.router.navigateByUrl('frequently-asked-questions')
      }
      if (type === 'contact') {
        this.router.navigateByUrl('contact-us');
      }
      if (type === 'privacy policy') {
        this.router.navigateByUrl('privacy-policy');
      }
      if (type === 'T&C') {
        window.open(`${AppConstants.BASE_URL_HOME}terms-and-conditions`, '_blank');
      }
      if (type === 'press') {
        this.router.navigateByUrl('press');
      }
      if(type === 'partner'){
        this.router.navigateByUrl('partners');
      }
      if(type === 'phlebotomist'){
        this.router.navigateByUrl('mobile-phlebotomist');
      }
      if(type === 'testcodes'){
        this.router.navigateByUrl('testcodes');

      }
    }, 1000);
  }

  accreditationSchedule() {
    window.open('https://www.ukas.com/wp-content/uploads/schedule_uploads/971214/22226-Medical-Multiple.pdf')
  }

}
