const server = {
    //prod env
    // home: 'https://conceptodiagnostics.co.uk/',
    // domain: '.conceptodiagnostics.co.uk',
    // api_endpoint: 'https://lims.conceptoclinicapp.co.uk/',
    // appointmentForms: 'https://forms.conceptoclinicapp.co.uk/',
    // storeId: '2206285717',
    // SharedS: 'tlOou0zAI0F70s4u',
    // GA4: true,


    //test env
    home: 'https://home.conceptoclinicapp.co.uk/',
    domain: '.conceptoclinicapp.co.uk',
    api_endpoint: 'https://qa-lims.conceptoclinicapp.co.uk/',
    appointmentForms: 'https://forms.conceptodiagnostics.co.uk/',
    storeId: '2220540956',
    SharedS: 'PTv8*>B8dJ',
    GA4: false,
    partner_portal: 'https://partner-portal.conceptodiagnostics.co.uk/'

    //test env

    //local env
    // home: 'http://localhost:4200/',
    // domain: "localhost",
    // storeId: '2220540956',
    // SharedS: 'PTv8*>B8dJ',
    // GA4: false,
    // api_endpoint: 'http://localhost:8080/',
    // api_endpoint_services: 'http://localhost:3000/',
    // partner_portal: 'http://localhost:8080/'
    //local env
};

export const AppConstants = {
    BASE_URL_HOME: server.home,
    // BASE_URL_IMAGING: server.imaging,
    // BASE_URL_NUTRITION: server.nutrition,
    API_ENDPOINT: server.api_endpoint,
    API_ENDPOINT_SERVICES: server.home,
    BASE_DOMAIN: server.domain,
    GA4: server.GA4,
    storeId: server.storeId,
    sharedS: server.SharedS,
    appointmentForms: server.appointmentForms,
    API_PARTNERPORTAL_ENDPOINT: server.partner_portal,

    API: {
        NEWSLETTER: {
            SUBSCRIBE: 'newsletter-subscribe',
        },
        PRODUCTS: {
            GET_ALL: 'products',
            BY_NAME: 'products/byName',
            BY_TYPE: 'products/findallByType'
        },
        CART: {
            UPDATE: 'carts/update',
            ADD: 'carts',
            BY_USER: 'carts/byuser',
        },
        ONBOARDING: {
            CREATE_NEW: 'onboarding',
            UPDATE: 'onboarding/update',
            GET_FORM_BY_ID: 'onboarding-forms/byid',
            UPLOAD_FILE: 'onboarding/addimage'
        },
    },
    PROFILE: {
        AUTH: {
            LOGIN: 'auth/login',
            SIGNUP: 'users',
            GUESTSIGNUP: 'users/guest',

            OTP_REQUEST: 'auth/login/otp',
            OTP_REQUEST_PASSWORD_RESET: 'auth/login/reset',
            UPDATE_PASSWORD: 'users/update/password'
        },
        USER: {
            USER_BY_EMAIL: 'users/byEmail',
            UPDATE: 'users/update'
        },
        TESTS: {
            DIAGNOSTICS_TESTS_BY_USER: 'test/diagnostics-tests'
        },
        REPORTS: {
            BASE_S3: 'email/link-base'
        },
        CART: {
            BY_USER: 'carts/byuser',
            UPDATE: 'carts/update',
            PURCHASED: 'carts/byuser/purchased',
            USER_CART_UPDATE_FROMTEMP: 'carts/byuser/update'
        },
        ORDERS: {
            ADD: 'orders',
            PAYMENT: 'orders/payment',
        },
        PROFILE: {
            NEWPRODUCTS: 'orders/byuser/orders',
            NUTRITION_TESTS_BY_USER: 'nutrition-packages/byEmail'
        },
        PRODUCTS: {
            GET_ALL: 'products',
        },
        TEMP: {
            succuss: 'orders/payment/success'
        }
    },
    CONCEPTO:{
        API:{
            UNSUBSCRIBE:'test/unsubscribe/cross-promotion',
            GET_UNSUBSCIRBE_INFO: 'test/unsubscribe'
        }
    },
    PARTNER_PORTAL: {
        API:{
            PARTNERS: 'partners/details'
        }
    }
};
