import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { PressComponent } from './press/press.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NiptHomeComponent } from './nipt-home/nipt-home.component';
import { IndividualNiptComponent } from './individual-nipt/individual-nipt.component';
import { MobilePhlebotomistComponent } from './mobile-phlebotomist/mobile-phlebotomist.component';
import { PartnerComponent } from './partner/partner.component';
import { ResourcesComponent } from './resources/resources.component';
import { confirmationComponent } from './confirmation/confirmation.component';
import { BlogComponent } from './blog/blog.component';
import { ClinicFinderComponent } from './clinic-finder/clinic-finder.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
import { IndividualBlogComponent } from './individual-blog/individual-blog.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { LoginComponent } from './login/login.component';
import { CartComponent } from './cart/cart.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UnsubscribeCrossPromotionalEmailsComponent } from './unsubscribe-cross-promotional-emails/unsubscribe-cross-promotional-emails.component';
import { TestCodesComponent } from './test-codes/test-codes.component';
import { ArchivedBlogComponent } from './archived-blogs/archived-blogs.component';
import { BookingPageComponent } from './booking-page/booking-page.component';



const routes: Routes = [

  { path: '', component: HomeComponent },
  { path: 'aboutus', component: AboutusComponent, pathMatch: 'full' },
  { path: 'press', component: PressComponent, pathMatch: 'full' },
  { path: 'nipt', component: NiptHomeComponent, pathMatch: 'full' },
  { path: 'nipt/:details', component: IndividualNiptComponent, pathMatch: 'full' },
  { path: 'partners', component: PartnerComponent, pathMatch: 'full' },
  { path: 'mobile-phlebotomist', component: MobilePhlebotomistComponent, pathMatch: 'full' },
  { path: 'resources', component: ResourcesComponent, pathMatch: 'full' },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'confirmation/:status', component: confirmationComponent },
  { path: 'clinic-finder', component: ClinicFinderComponent, pathMatch: 'full' },
  { path: 'blog', component: BlogComponent, pathMatch: 'full' },
  { path: 'individual-blog/:name', component: IndividualBlogComponent, pathMatch: 'full' },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent, pathMatch: 'full' },
  { path: 'frequently-asked-questions', component: FaqComponent, pathMatch: 'full' },
  { path: 'contact-us', component: ContactUsComponent, pathMatch: 'full' },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full' },
  { path: 'testcodes', component: TestCodesComponent, pathMatch: 'full' },
  { path: 'archived-blog', component: ArchivedBlogComponent, pathMatch: 'full' },
  { path: 'faqs/', redirectTo: '/faqs' },
  { path: 'faqs', redirectTo: 'frequently-asked-questions' },
  { path: 'booking', component: BookingPageComponent, pathMatch: 'full' },



  // clinics
  { path: 'clinics/essex/', redirectTo: '/clinics/essex', pathMatch: 'full' },
  { path: 'clinics/essex', redirectTo: '/clinic-finder?clinic=Essex', pathMatch: 'full' },

  { path: 'clinics/milton-keynes/', redirectTo: '/clinics/milton-keynes', pathMatch: 'full' },
  { path: 'clinics/milton-keynes', redirectTo: '/clinic-finder?clinic=Milton-Keynes', pathMatch: 'full' },

  { path: 'clinics/oxford/', redirectTo: '/clinics/oxford', pathMatch: 'full' },
  { path: 'clinics/oxford', redirectTo: '/clinic-finder?clinic=Oxford', pathMatch: 'full' },

  { path: 'clinics/north-london/', redirectTo: '/clinics/north-london', pathMatch: 'full' },
  { path: 'clinics/north-london', redirectTo: '/clinic-finder?clinic=North-London', pathMatch: 'full' },

  { path: 'clinics/bristol/', redirectTo: '/clinics/bristol', pathMatch: 'full' },
  { path: 'clinics/bristol', redirectTo: '/clinic-finder?clinic=Bristol', pathMatch: 'full' },

  { path: 'clinics/Hitchin/', redirectTo: '/clinics/Hitchin', pathMatch: 'full' },
  { path: 'clinics/Hitchin', redirectTo: '/clinic-finder?clinic=Hitchin', pathMatch: 'full' },


  { path: 'clinics/Aylesbury/', redirectTo: '/clinics/Aylesbury', pathMatch: 'full' },
  { path: 'clinics/Aylesbury', redirectTo: '/clinic-finder?clinic=Aylesbury', pathMatch: 'full' },

  { path: 'clinics/heathrow-airport/', redirectTo: '/clinics/heathrow-airport', pathMatch: 'full' },
  { path: 'clinics/heathrow-airport', redirectTo: '/clinic-finder?clinic=Heathrow-Airport', pathMatch: 'full' },

  { path: 'clinics/london/', redirectTo: '/clinics/london', pathMatch: 'full' },
  { path: 'clinics/london', redirectTo: '/clinic-finder?clinic=Central-London', pathMatch: 'full' },

  { path: 'clinics/cambridge/', redirectTo: '/clinics/cambridge', pathMatch: 'full' },
  { path: 'clinics/cambridge', redirectTo: '/clinic-finder?clinic=Cambridge', pathMatch: 'full' },

  { path: 'clinics/watford/', redirectTo: '/clinics/watford', pathMatch: 'full' },
  { path: 'clinics/watford', redirectTo: '/clinic-finder?clinic=Watford', pathMatch: 'full' },

  { path: 'clinics/west-london/', redirectTo: '/clinics/west-london', pathMatch: 'full' },
  { path: 'clinics/west-london', redirectTo: '/clinic-finder?clinic=West-London', pathMatch: 'full' },

  { path: 'clinics/south-london/', redirectTo: '/clinics/south-london', pathMatch: 'full' },
  { path: 'clinics/south-london', redirectTo: '/clinic-finder?clinic=South-London', pathMatch: 'full' },

  { path: 'clinics/gatwick-airport/', redirectTo: '/clinics/gatwick-airport', pathMatch: 'full' },
  { path: 'clinics/gatwick-airport', redirectTo: '/clinic-finder?clinic=gatwick-Airport', pathMatch: 'full' },


  //profile
  { path: 'login', component: LoginComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'profile', component: UserProfileComponent },
  { path: 'cart', component: CartComponent },
  { path: 'unsubscribe', component: UnsubscribeCrossPromotionalEmailsComponent },


  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    }

  ],
})
export class AppRoutingModule { }
