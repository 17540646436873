import { Component, OnInit, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppConstants } from '../app.constants';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-individual-nipt',
  templateUrl: './individual-nipt.component.html',
  styleUrls: ['./individual-nipt.component.scss']
})
export class IndividualNiptComponent implements OnInit {
  customerTestArray: any = [];
  card_details: any = [];
  show: boolean;
  image: string;
  activeImage: number = 0;
  constructor(private router: Router, private metaService: Meta, private titleService: Title, @Inject(DOCUMENT) private dom) {
    this.metaService.updateTag({ name: 'description', content: "Concepto Diagnostics provides NIPT tests in the UK to expectant parents looking for a safe & reliable method of detecting genetic disorders in an unborn child via an Illumina prenatal test in the UK." });
    this.metaService.updateTag({ name: 'keywords', content: 'NIPT Test UK, Illumina Prenatal Test UK, Genetics Testing UK, Non-Invasive Prenatal screening Test, Non-Invasive screening test UK,Prenatal screening test UK,' });
    this.titleService.setTitle('NIPT Screening: Non-Invasive Prenatal Test UK - Concepto Diagnostics');
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href','https://conceptodiagnostics.co.uk/nipt')
  }

  ngOnInit(): void {
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if (menuitem[i]["outerText"] === "Concepto-NIPT") {
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)
    this.customerTestArray = [
      // {
      //   link: "nipt",
      //   images: ["../../assets/images/nipt/NIPT-1.png","../../assets/images/nipt/nipt-basic.png"],       
      //   title: "Concepto-NIPT Standard",
      //   description: ['Book this test if you have a confirmed gestation of 10 weeks or more and an ultrasound report', 'Concepto-NIPT is a safe and non-invasive screening test which involves collection of maternal blood sample. It detects the following chromosomal anomalies:'],
      //   list: [
      //     "Down Syndrome (Trisomy 21) ",
      //     "Edwards Syndrome (Trisomy 18) ",
      //     "Patau Syndrome (Trisomy 13) ",
      //     "Gender- Male (XY) Female (XX) - (Optional)",
      //   ],
      //   description_two: 'Concepto-NIPT also detects the gender of the fetus.',
      //   description_three: 'When you arrive for your appointment:',
      //   list_one: [
      //     "A trained professional will confirm your identity and appointment details ",
      //     "Our trained team member will brief you about your Prenatal Test and ask you to fill a form",
      //     "A professional will also collect blood sample from your arm ",
      //     "The sample will be sent to our lab ",
      //     "The lab will process and analyse the sample ",
      //     "The results will be sent to email id that you have provided while booking your appointment"
      //   ],
      //   price: "199",
      //   button: "BOOK NOW",
      //   booking_id: "41043564",
      //   type: 'concepto'
      // },
      {
        link: "nipt-advance",
        images: ["../../assets/images/nipt/NIPT-1.png","../../assets/images/nipt/nipt-advance.png","../../assets/images/nipt/nipt-advance-gender.png"],
        title: "Concepto-NIPT Advance",
        description: ['NIPT Advance is a safe and non-invasive screening test which detects abnormalities from a simple blood sample. It’s not only the most affordable NIPT test but, as it’s backed by Illumina, it’s also the most accurate in the UK.', 'It includes gender reveal , which costs £129 on its own(optional, of course).', 'You can book this test from a confirmed gestation of 10 weeks if you have an ultrasound report and the results will be with you in up to 5 working days.', 'NIPT Advance detects the following chromosomal anomalies:'],
        list: [
          "Down Syndrome (Trisomy 21) ",
          "Edwards Syndrome (Trisomy 18) ",
          "Patau Syndrome (Trisomy 13) ",
          "Gender- Male (XY) Female (XX) - (Optional)",
          "Sex chromosome anomalies"
        ],
        description_two: '',
        description_three: "Here's what to expect at your appointment...",
        list_one: [
          "A trained professional will greet you and confirm your identity and appointment details",
          "They will then brief you about your test and ask you to fill in a consent form",
          "A blood sample is collected from your arm and sent to our UK lab",
          "We will process and email the results within 5 working days once the sample reaches our lab"
        ],
        price: "From £299",
        button: "BOOK NOW",
        booking_id: "48202503",
        type: 'concepto'
      },
      // {
      //   link: "nipt-advance",
      //   images: ["../../assets/images/nipt/NIPT-1.png" ,"../../assets/images/nipt/nipt-advance.png"],
      //   title: "Concepto-NIPT Advance",
      //   description: ['Book this test if you have a confirmed gestation of 10 weeks or more and an ultrasound report', 'Concepto-NIPT is a safe and non-invasive screening test which involves collection of maternal blood sample. It detects the following chromosomal anomalies:'],
      //   list: [
      //     "Down Syndrome (Trisomy 21) ",
      //     "Edwards Syndrome (Trisomy 18) ",
      //     "Patau Syndrome (Trisomy 13) ",
      //     "Gender- Male (XY) Female (XX) - (Optional)",
      //     "Sex chromosome anomalies",
      //     "1 Microdeletion"

      //   ],
      //   description_two: 'Concepto-NIPT also detects the gender of the fetus.',
      //   description_three: 'When you arrive for your appointment:',
      //   list_one: [
      //     "A trained professional will confirm your identity and appointment details ",
      //     "Our trained team member will brief you about your Prenatal Test and ask you to fill a form",
      //     "A professional will also collect blood sample from your arm ",
      //     "The sample will be sent to our lab ",
      //     "The lab will process and analyse the sample ",
      //     "The results will be sent to email id that you have provided while booking your appointment"
      //   ],
      //   price: "249",
      //   button: "BOOK NOW",
      //   booking_id: "48202503",
      //   type: 'concepto'
      // }
      // {
      //   link: "nipt-home-service",
      //   defaultImg: "../../assets/images/nipt/NIPT-1.png",
      //   images: "../../assets/images/nipt/nipt-basic.png",
      //   title: "Concepto-NIPT Home Service",
      //   description: ["UK's most safe, accurate and affordable prenatal screening test is now available at your home.", "Book this test if you have a confirmed gestation of 10 weeks or more and an ultrasound report", "Concepto-NIPT is a safe and non-invasive screening test which involves collection of maternal blood sample. It detects the following chromosomal anomalies:"],
      //   list: [
      //     "Down Syndrome (Trisomy 21) ",
      //     "Edwards Syndrome (Trisomy 18) ",
      //     "Patau Syndrome (Trisomy 13) ",
      //     "Gender- Male (XY) Female (XX) - (Optional)"
      //   ],
      //   description_two: 'Concepto-NIPT also detects the gender of the fetus',
      //   description_three: 'When you book this test from the convenience of your home:',

      //   list_one: [
      //     "A kit arrives at your address",
      //     "A Concepto-NIPT team member calls and confirms a home visit appointment",
      //     "Our staff member visits at the designated time, briefs you about your Prenatal Test and asks you to fill a form",
      //     "The professional collects blood sample from your arm",
      //     "The sample is sent to our lab",
      //     "The lab processes and analyses the sample",
      //     "The results will be sent to email id that you have provided while booking your appointment"
      //   ],
      //   price: "219",
      //   button: "ORDER NOW",
      //   // booking_id: "https://buy.stripe.com/test_cN24iWaaX8738py9AC",
      //   booking_id: "https://buy.stripe.com/7sI3fxfy60RQguc4gi",
      //   type: 'stripe'

      // }
    ]
    let getUrl = this.router.url.split('/')[this.router.url.split('/').length -1];
    if (getUrl.includes('&')) {
      getUrl = getUrl.split('&')[0];     
    }
    if (getUrl && getUrl.length > 0) {
      let card_details = this.customerTestArray.filter(item => item.link === getUrl)[0];
      if (card_details) {     
        this.card_details = card_details
      } else {
        this.router.navigate(['/page-not-found'])
      }
    } else {
      this.router.navigate(['/page-not-found'])
    }
  }

  displaySelectedImage(index:any) {
    this.show = true;
    this.image = this.card_details.images[index]; 
    this.activeImage = index;
  }

  booknow() {
    let p = this.card_details;
    if (p.type === 'concepto') {
      this.router.navigateByUrl('/booking')
      // window.open(`${AppConstants.appointmentForms}location?appointmentTypeID=${p.booking_id}`)
    } else if (p.type === 'stripe') {
      window.open(`${p.booking_id}`)
    }
  }
}


