import { Component, OnInit, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppConstants } from '../app.constants';
import { DOCUMENT } from '@angular/common';
import { GeneralService } from '../general.service';
@Component({
  selector: 'app-booking-page',
  templateUrl: './booking-page.component.html',
  styleUrls: ['./booking-page.component.scss']
})
export class BookingPageComponent implements OnInit {
  loading: boolean = false
  partnerDetails: any = []
  selectedPostcode: any;
  filteredPostcode: any[];
  partnerDetailsNearestPostcodes: any[] = [];
  nonMatchingPostcodes: any[] = [];
  constructor(private router: Router, private metaService: Meta, private titleService: Title, @Inject(DOCUMENT) private dom, private generalService: GeneralService) {
    this.metaService.updateTag({ name: 'description', content: "Concepto Diagnostics provides NIPT tests in the UK to expectant parents looking for a safe & reliable method of detecting genetic disorders in an unborn child via an Illumina prenatal test in the UK." });
    this.metaService.updateTag({ name: 'keywords', content: 'NIPT Test UK, Illumina Prenatal Test UK, Genetics Testing UK, Non-Invasive Prenatal screening Test, Non-Invasive screening test UK,Prenatal screening test UK,' });
    this.titleService.setTitle('NIPT Screening: Non-Invasive Prenatal Test UK - Concepto Diagnostics');
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', 'https://conceptodiagnostics.co.uk/booking')
  }

  ngOnInit(): void {
    this.getPartners();
  }

  getPartners() {
    this.loading = true;
    this.generalService.getPartnersDetails().subscribe(async res => {
      this.partnerDetails = []
      if (res && res['results'].length > 0) {
        await res['results'].map(async item => {
          if (item.isDeleted == false) {
            if (item.branch && item.branch.length === 0) {
              this.partnerDetails.push(item)
            } else if (item.branch.length > 0) {
              await item.branch.map(branch => {
                branch.tradingName = branch.name;
                // branch.zipCode = '';
                this.partnerDetails.push(branch)
              })
            }
          }
        })
        this.loading = false;
        console.log(this.partnerDetails)
      }
    })

  }

  filterPostcode(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let query = event.query;
    this.generalService.getRelatedPostcodes(query).subscribe(data => {
      if (data && data['result'] && data['result'].length > 0) {
        this.filteredPostcode = data['result'];
      } else {
        this.filteredPostcode = []
      }
    });
  }

  async postcodeSelected() {
    this.loading = true;
    this.partnerDetailsNearestPostcodes = [];
    this.nonMatchingPostcodes = [];
    let initNonMatchingPostcodes = [];
    this.generalService.getNearestPostcodes(this.selectedPostcode).subscribe(async data => {
      if (data && data['result'] && data['result'].length > 0) {
        // this.partnerDetailsNearestPostcodes = result;
        this.partnerDetailsNearestPostcodes = this.partnerDetails.reduce((acc, o1) => {
          // Find the matching entry in data['result']
          const matchingEntry = data['result'].find(o2 =>
            o1.zipCode.toLowerCase().replace(/ /g, '') === o2.postcode.toLowerCase().replace(/ /g, '')
          );

          // If a matching entry is found, merge the properties into a new object
          if (matchingEntry) {
            const distanceInMiles = matchingEntry.distance ? (matchingEntry.distance / 1609.34).toFixed(2) : '0';
            acc.push({ ...o1, ...matchingEntry, distanceInMiles: parseFloat(distanceInMiles) }); // Merge o1 and o2
          } else {
            // If no match is found, push the original entry to a separate array
            const distanceInMiles = matchingEntry?.distance ? (matchingEntry?.distance / 1609.34).toFixed(2) : '0';
            initNonMatchingPostcodes.push({ ...o1, ...matchingEntry, distanceInMiles: parseFloat(distanceInMiles) });;
          }
          this.loading = false;
          return acc;
        }, []);
        let postCodeArr = []
        console.log(this.partnerDetailsNearestPostcodes)
        await initNonMatchingPostcodes.forEach((element, i) => {
          if (element.zipCode && element.zipCode.length > 0) {
            postCodeArr.push(element.zipCode)
          }
          if (i === initNonMatchingPostcodes.length - 1) {
            let postCodetoSend = postCodeArr.slice(0, 100);

            this.generalService.getNearestPostcodesBulk(postCodetoSend).subscribe(async data1 => {
              console.log(data1)
              let nonMatchingPostcodes = initNonMatchingPostcodes.reduce((acc, o11) => {
                // Find the matching entry in data['result']
                const matchingEntry = data1['result'].find((o2: any) =>
                  o11.zipCode.toLowerCase().replace(/ /g, '') === o2.query.toLowerCase().replace(/ /g, '')
                );

                // If a matching entry is found, merge the properties into a new object
                if (matchingEntry) {
                  const distanceInMiles = matchingEntry.distance ? (matchingEntry.distance / 1609.34).toFixed(2) : '0';
                  console.log(matchingEntry)
                  o11['calculatedDistance'] = this.haversineDistance(this.partnerDetailsNearestPostcodes[0].latitude, this.partnerDetailsNearestPostcodes[0].longitude, matchingEntry.result?.latitude , matchingEntry.result?.longitude )
                  acc.push({ ...o11, ...matchingEntry, distanceInMiles: parseFloat(distanceInMiles) }); // Merge o1 and o2
                }

                return acc;
              }, []);
              this.loading = false;
              this.nonMatchingPostcodes = nonMatchingPostcodes.sort((a, b) => a.calculatedDistance - b.calculatedDistance);
              console.log(this.nonMatchingPostcodes)

            })
          }
        });
      }
    })
  }

  getMiles(meters) {
    return meters * 0.000621371192;
  }

  haversineDistance(lat1, lon1, lat2, lon2) {
    const toRad = (value) => value * Math.PI / 180; // Convert degrees to radians

    const R = 3958.8; // Radius of the Earth in miles

    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);
              
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return (R * c).toFixed(2); // Distance in miles
}

  navigateToBooking(link) {
    window.open(link, "_blank");
  }
}


