import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  items: any = [];
  visibleSidebar = false;
  activeClick: any;
  pageYoffset = 0;
  addedToCart: any;
  userLoggedIn = true;
  appointmentName = 'Concepto';
  displayCartLogo = true
  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }

  constructor(private router: Router, private scroll: ViewportScroller, private generalService: GeneralService) {
    this.router.events.subscribe(value => {
      if (this.router.url.toString() === '/nipt') {
        this.appointmentName = 'NIPT';
      } else if (this.router.url.toString().includes('/nipt')) {
        this.appointmentName = 'NIPT';
      } else {
        this.appointmentName = 'Concepto';
      }
    });
    this.addedToCart = this.generalService.getCookie('coc_active_cart')
    if (this.addedToCart === null || this.addedToCart === undefined) {
      this.addedToCart = false;
    }

    let existing_user = this.generalService.getCookie('coc_user')
    if (existing_user) {
      this.userLoggedIn = true
      this.addLogout()
    } else {
      this.userLoggedIn = false
      this.removeLogout()
    }

    this.generalService.listenCookieChange(({ oldValue, newValue }) => {
      if (newValue && newValue.length > 0) {
        let value = newValue.split(';')
        if (value && value.length > 0) {
          value.forEach(element => {
            if (element.includes('coc_active_cart')) {
              let cartstatus = element.split('=')[1];
              if (cartstatus === 'true') {
                this.addedToCart = true
              } else {
                this.addedToCart = false
              }
            }
          });
        }
      }


    }, 1000);
  }

  ngOnInit(): void { }

  profile() {
    let existing_user = this.generalService.getCookie('coc_user')
    if (existing_user) {
      this.router.navigateByUrl(`profile`);
      this.visibleSidebar = false;
      this.userLoggedIn = true
      this.addLogout()
    } else {
      this.router.navigateByUrl(`login`);
      this.visibleSidebar = false;
      this.userLoggedIn = false
      this.removeLogout()
    }
  }

  cart() {
    this.router.navigateByUrl(`cart`);
    this.visibleSidebar = false;
    let existing_user = this.generalService.getCookie('coc_user')
    if (existing_user) {
      this.userLoggedIn = true
      this.addLogout()
    } else {
      this.userLoggedIn = false
      this.removeLogout()
    }
  }

  logout() {
    this.router.navigateByUrl(`login`);
    this.visibleSidebar = false;
    this.userLoggedIn = false
    this.generalService.clearCookie();
    this.removeLogout()
  }

  faqs() {
    this.router.navigateByUrl(`frequently-asked-questions`);
    this.visibleSidebar = false;
  }

  contactUs() {
    this.router.navigateByUrl(`contact-us`);
    this.visibleSidebar = false;
  }

  landing() {
    this.router.navigate(['']);
    this.visibleSidebar = false;
  }

  aboutus() {
    this.router.navigate(['/aboutus'])
    this.visibleSidebar = false;
  }

  blog() {
    this.router.navigate(['/blog']);
    this.visibleSidebar = false;
  }

  nipt() {
    this.router.navigate(['/nipt']);
    this.visibleSidebar = false;
  }

  menuClick(input) {
    if (input === 'BlueorPink') {
      window.open(
        'https://blueorpink.co.uk/product/early-gender-test?utm_source=concepto&utm_medium=referal',
        '_blank'
      );
      this.visibleSidebar = false;
    } else if (input === 'NIPT') {
      this.router.navigate(['/nipt']);
      this.visibleSidebar = false;
    }
  }

  blogs() {
    this.router.navigateByUrl(`blog`);
    this.visibleSidebar = false;
  }

  findClinic() {
    this.router.navigateByUrl(`clinic-finder`);
    this.visibleSidebar = false;
  }

  visibility() {
    this.visibleSidebar = true;
  }

  closeSidebar() {
    this.visibleSidebar = false
  }

  activeclick(input) {
    this.activeClick = input;
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  removeLogout() {
    this.items = [
      { label: 'Concepto-NIPT', command: () => this.menuClick('NIPT') },
      { label: 'BlueorPink - Early Gender Test', command: () => this.menuClick('BlueorPink') },
      {
        label: 'More',
        items: [
          { label: 'Profile', command: () => this.profile() },
          { label: 'Blog', command: () => this.blogs() },
          { label: 'FAQs', command: () => this.faqs() },
          { label: 'About Us', command: () => this.aboutus() },
          { label: 'Contact Us', command: () => this.contactUs() },
        ]
      }
    ]
  }

  addLogout() {
    this.items = [
      { label: 'Concepto-NIPT', command: () => this.menuClick('NIPT') },
      { label: 'BlueorPink - Early Gender Test', command: () => this.menuClick('BlueorPink') },
      {
        label: 'More',
        items: [
          { label: 'Profile', command: () => this.profile() },
          { label: 'Blog', command: () => this.blogs() },
          { label: 'FAQs', command: () => this.faqs() },
          { label: 'About Us', command: () => this.aboutus() },
          { label: 'Contact Us', command: () => this.contactUs() },
          { label: 'Logout', command: () => this.logout() },
        ]
      }
    ]

  }
}
