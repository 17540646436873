import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { HomeComponent } from './home/home.component';
import { CarouselModule } from 'primeng/carousel';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';

import { MenubarModule } from 'primeng/menubar';
import { GMapModule } from 'primeng/gmap';
import { AccordionModule } from 'primeng/accordion';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HeaderComponent } from './header/header.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { BlogComponent } from './blog/blog.component';
import { FooterComponent } from './footer/footer.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { GeneralService } from './general.service';
import { PressComponent } from './press/press.component';
import {TableModule} from 'primeng/table';
import {MatTabsModule} from '@angular/material/tabs';
import { CartbuttonComponent } from './cartbutton/cartbutton.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NiptHomeComponent } from './nipt-home/nipt-home.component';
import { IndividualNiptComponent } from './individual-nipt/individual-nipt.component';

import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { LoaderComponent } from './loader/loader.component';
import { ResourcesComponent } from './resources/resources.component';
import { PartnerComponent } from './partner/partner.component';
import { MobilePhlebotomistComponent } from './mobile-phlebotomist/mobile-phlebotomist.component';
import { confirmationComponent } from './confirmation/confirmation.component';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import { ShareModule } from 'ngx-sharebuttons';
import { DialogModule } from 'primeng/dialog';
import { BannerComponent } from './banner/banner.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CartHandlerComponent } from './cart-handler/cart-handler.component';
import { CartPopverComponent } from './cart-popver/cart-popver.component';
import { ClinicFinderComponent } from './clinic-finder/clinic-finder.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
import { IndividualBlogComponent } from './individual-blog/individual-blog.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { BoldPipe } from '../assets/pipes/bold.pipe';
import { CartComponent } from './cart/cart.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { MessageService } from 'primeng/api';
import { UnsubscribeCrossPromotionalEmailsComponent } from './unsubscribe-cross-promotional-emails/unsubscribe-cross-promotional-emails.component';
import { TestCodesComponent } from './test-codes/test-codes.component';
import { ArchivedBlogComponent } from './archived-blogs/archived-blogs.component';
import { BookingPageComponent } from './booking-page/booking-page.component';


declare global {
  interface Window { dataLayer: any[]; }
}
@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    HomeComponent,
    HeaderComponent,
    AboutusComponent,
    BlogComponent,
    FooterComponent,
    ComingSoonComponent,
    PressComponent,
    CartbuttonComponent,
    PageNotFoundComponent,
    NiptHomeComponent,
    IndividualNiptComponent,
    MobilePhlebotomistComponent,
    PartnerComponent,
    LoaderComponent,
    ResourcesComponent,
    confirmationComponent,
    ClinicFinderComponent,
    TermsAndConditionsComponent,
    FaqComponent,
    ContactUsComponent,
    PrivacyPolicyComponent,
    IndividualBlogComponent,
    BannerComponent,
    BoldPipe,
    BreadcrumbsComponent,
    CartPopverComponent,
    CartHandlerComponent,
    UserProfileComponent,
    SignUpComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    CartComponent,
    UnsubscribeCrossPromotionalEmailsComponent,
    TestCodesComponent,
    ArchivedBlogComponent,
    BookingPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CarouselModule,
    TabViewModule,
    InputTextModule,
    MenubarModule,
    GMapModule,
    AccordionModule,
    SidebarModule,
    TooltipModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatTabsModule,
    TableModule,
    MultiSelectModule,
    RadioButtonModule,
    FileUploadModule,
    ToastModule,
    DialogModule,
    ShareModule,
    BreadcrumbModule,  
    CalendarModule,
    DropdownModule,
    AutoCompleteModule
  ],
  providers: [GeneralService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
