import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { AppConstants } from './app.constants';




@Injectable()
export class GeneralService {
  constructor(private http: HttpClient) { }

  setCookie(name, value, days) {
    var expires = "";
    let domain = AppConstants.BASE_DOMAIN;

    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + ";domain=" + domain + "; path=/";
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  clearCookie() {
    var expires = "";
    let domain = AppConstants.BASE_DOMAIN;
    var date = new Date();
    date.setTime(date.getTime() + (2000));
    expires = "; expires=" + date.toUTCString();
    document.cookie = 'coc_token' + "=" + ("" || "") + expires + ";domain=" + domain + "; path=/";
    document.cookie = 'coc_user' + "=" + ("" || "") + expires + ";domain=" + domain + "; path=/";
  }

  clearCartCookie() {
    var expires = "";
    let domain = AppConstants.BASE_DOMAIN;
    var date = new Date();
    date.setTime(date.getTime() + (2000));
    expires = "; expires=" + date.toUTCString();
    document.cookie = 'coc_active_cart' + "=" + (false || "") + expires + ";domain=" + domain + "; path=/";
  }

  listenCookieChange(callback, interval = 1000) {
    let lastCookie = document.cookie;
    setInterval(() => {
      let cookie = document.cookie;
      if (cookie !== lastCookie) {
        try {
          callback({ oldValue: lastCookie, newValue: cookie });
        } finally {
          lastCookie = cookie;
        }
      }
    }, interval);
  }

  ClearCookieByName(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  submitNewsletterSubscribe(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.API.NEWSLETTER.SUBSCRIBE}`,
      data);
  }

  getBase64FromS3Link(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}email/link-base`, data);
  }

  createOnboarding(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.API.ONBOARDING.CREATE_NEW}`, data);
  }

  getQuestions(id): Observable<any[]> {
    return this.http.get<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.API.ONBOARDING.GET_FORM_BY_ID}?id=${id}`);
  }

  uploadDocument(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.API.ONBOARDING.UPLOAD_FILE}`, data);
  }

  getProducts(): Observable<any[]> {
    return this.http.get<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.API.PRODUCTS.GET_ALL}`);
  }

  getProductByName(name): Observable<any[]> {
    return this.http.get<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.API.PRODUCTS.BY_NAME}?name=${name}`);
  }

  addToCart(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.API.CART.ADD}`, data);
  }

  getProductsByType(type): Observable<any[]> {
    return this.http.get<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.API.PRODUCTS.BY_TYPE}?type=${type}`);
  }

  getCartByUser(type, user): Observable<any[]> {
    return this.http.get<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.API.CART.BY_USER}?type:${type}&user:${user}`);
  }

  cartUpdate(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.API.CART.UPDATE}`, data);
  }

  submitLogin(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.AUTH.LOGIN}`, data);
  }

  usersignup(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.AUTH.SIGNUP}`, data);
  }
  usersignupGuest(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.AUTH.GUESTSIGNUP}`, data);
  }

  otpRequest(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.AUTH.OTP_REQUEST}`, data);
  }

  otpRequestPasswordReset(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.AUTH.OTP_REQUEST_PASSWORD_RESET}`, data);
  }

  userByEmail(data): Observable<any[]> {
    return this.http.get<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.USER.USER_BY_EMAIL}?email=${data}`);
  }

  getDiagnosticsTestsForUser(data) {
    return this.http.get<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.PROFILE.TESTS.DIAGNOSTICS_TESTS_BY_USER}?email=${data}`,);
  }

  getNutritionTestsForUser(data) {
    return this.http.get<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.PROFILE.NUTRITION_TESTS_BY_USER}?email=${data}`,);
  }


  getorderDetailsforProfile(type, user): Observable<any[]> {
    return this.http.get<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.PROFILE.NEWPRODUCTS}?type:${type}&user:${user}`);
  }



  updateCartByTempUser(type, user, email): Observable<any[]> {
    return this.http.get<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.CART.USER_CART_UPDATE_FROMTEMP}?type:${type}&user:${user}&email:${email}`);
  }


  getCartByUserPurchased(type, user): Observable<any[]> {
    return this.http.get<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.CART.PURCHASED}?type:${type}&user:${user}`);
  }

  updateUser(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.USER.UPDATE}`, data);
  }
  updateUserPassword(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.AUTH.UPDATE_PASSWORD}`, data);
  }

  addOrder(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.ORDERS.ADD}`, data);
  }

  makePayment(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.ORDERS.PAYMENT}`, data);
  }

  validateZipCode(zipcode): Observable<any[]> {
    return this.http.get<any[]>(`https://postcodes.io/postcodes/${zipcode}`);
  }

  tempSuccus(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT_SERVICES}${AppConstants.PROFILE.TEMP.succuss}`, data);
  }

  unsubscribCrossePromotionalEmails(data): Observable<any[]> {
    return this.http.post<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.CONCEPTO.API.UNSUBSCRIBE}`, data);
  }

  checkIfEmailUnsubscribed(email, type, token): Observable<any[]> {
    return this.http.get<any[]>(`${AppConstants.API_ENDPOINT}${AppConstants.CONCEPTO.API.GET_UNSUBSCIRBE_INFO}?email=${email}&type=${type}&token=${token}`);
  }

  getPartnersDetails(): Observable<any[]> {
    return this.http.get<any[]>(`${AppConstants.API_PARTNERPORTAL_ENDPOINT}${AppConstants.PARTNER_PORTAL.API.PARTNERS}`);
  }

  getRelatedPostcodes(input): Observable<any[]> {
    return this.http.get<any[]>(`https://api.postcodes.io/postcodes/${input}/autocomplete`);
  }

  getNearestPostcodes(input): Observable<any[]> {
    return this.http.get<any[]>(`https://api.postcodes.io/postcodes/${input}/nearest?limit=200&radius=4000`);
  }

  getNearestPostcodesBulk(data): Observable<any[]> {
    let dataToSend = {
      "postcodes" :data
    }
    return this.http.post<any[]>(`https://api.postcodes.io/postcodes`, dataToSend);
  }

  
  
  
}
