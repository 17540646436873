import { AfterContentInit, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppConstants } from '../app.constants';
import { GeneralService } from '../general.service';
import { Carousel } from 'primeng/carousel';

// declare global {
//   interface Window {
//     Trustpilot: any;
//   }
// }

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, AfterContentInit {

  products: any = [];
  clients: any[];
  accreditations: any[];
  test_and_profile: any;
  subscribe = 'Subscribe';
  subscribed = false;
  email = '';
  disabled = false;
  loginForm!: FormGroup;
  submitted = false;
  // showTrustPilot = false;
  trendingarticles = []

  constructor(private router: Router, private formBuilder: FormBuilder, private generalService: GeneralService, private metaService: Meta, private titleService: Title) {
    // this.metaService.updateTag({ name: 'description', content: "UK's leading diverse British healthcare company -Concepto Diagnostics Concepto Diagnostics is a decade old British healthcare company with expertise ranging from Diagnostics - Covid (RT-PCR) (Same Day Travel Tests), Blood tests, Nutrition, Wellness products and Genomics." });
    Carousel.prototype.onTouchMove = () => { };
    this.trendingarticles = [
      {
        title: "Non invasive screening test for Downs syndrome in UK",
        image: "Downs-Syndrome-test-in-UK.png",
        shortdescription: "Down’s syndrome is among the most common chromosomal conditions in the UK, affecting 1 in every 700 births. Down’s syndrome tests in UK are offered by NHS and private clinics. Let us look at what is Down’s syndrome, its causes, and how Down’s Syndrome screening tests during pregnancy help in early detection.",
        link: "Downs-Syndrome-test-in-UK",
        date: "17 Apr 2024",
      },
      {
        title: "NIPT test accuracy - detailed statistics and limitations",
        image: "NIPT-test-accuracy-detailed-statistics-and-limitations.png",
        shortdescription: "Pregnancy can be hard. It makes you anxious, worried and tired - and, the cycle keeps repeating. Well, the only way we can offer peace of mind to you all anxious parents-to-be, is through our NIPT test , that accurately screens for three of the most common chromosomal anomalies: Down syndrome, Edwards syndrome, and Patau syndrome",
        link: "NIPT-test-accuracy-detailed-statistics-and-limitations",
        date: "21 Jul 2022",
      },
      {
        title: "Most advanced NIPT test during pregnancy in the UK",
        image: "Most-advanced-NIPT-test-during-pregnancy-in-the-UK.png",
        shortdescription: "Worried? Confused? At your wit's end after reading complex journals on NIPT? Don't worry, we have you covered. Let us walk you through some basics of NIPT test during pregnancy.",
        link: "Most-advanced-NIPT-test-during-pregnancy-in-the-UK",
        date: "21 Jul 2022",

      },
      {
        title: "What is functional testing in nutrition and medicine?",
        image: "What-is-functional-testing-in-nutrition-and-medicine.png",
        shortdescription: "In some cases ailments are easy to identify and address, allowing you to quickly uncover the root cause and adapt your diet or lifestyle accordingly. Often, however, there is greater complexity and there does not appear to be a rhyme or reason in these situations. This is where functional testing can be extremely beneficial.",
        link: "What-is-functional-testing-in-nutrition-and-medicine",
        date: "21 Jul 2022",

      }
    ]
  }

  ngAfterContentInit() {
    // const trustboxRef = document.getElementById('trustbox');
    // const trustboxsmall = document.getElementById('trustbox-small');
    // if (window.Trustpilot) {
    //   window.Trustpilot.loadFromElement(trustboxRef);
    //   window.Trustpilot.loadFromElement(trustboxsmall);
    // }
    // setTimeout(() => {
    //   this.showTrustPilot = true
    // }, 1000);
  }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });

    // const trustboxRef = document.getElementById('trustbox');
    // window.Trustpilot.loadFromElement(trustboxRef);

    let menuitem = document.getElementsByClassName("p-menuitem");
    for (let i = 0; i < menuitem.length; i++) {
      menuitem[i].classList.remove("active");
    }
    window.scrollTo(0, 0);
    this.products = [
      {
        name: 'Rakshan Rajesh',
        grade: 'Grade 6',
        comment: 'The LearnPanda course improved my interest towards learning AI programming. The teacher made the topics clear and interesting'
      },
      {
        name: 'Joseph Stallon',
        grade: 'Grade 7',
        comment: 'The LearnPanda course improved my interest towards learning AI programming. The teacher made the topics clear and interesting'
      },
      {
        name: 'Rajesh Rakshan',
        grade: 'Grade 8',
        comment: 'The LearnPanda course improved my interest towards learning AI programming. The teacher made the topics clear and interesting'
      }
    ];

    this.clients = [
      "../../../../assets/images/contents/emirates.jpg",
      "../../../../assets/images/contents/triumph.jpg",
      "../../../../assets/images/contents/surrey.jpg",
      "../../../../assets/images/contents/berkeley.jpg",
      "../../../../assets/images/contents/burberry.jpg",
      "../../../../assets/images/contents/hyatt.jpg",
      "../../../../assets/images/contents/hilton.jpg",
    ];
    this.accreditations = [
      "../../../../assets/images/contents/ccq.png",
      // "../../../../assets/images/contents/ukas.png",
      // "../../../../assets/images/contents/uk health.png",
      "../../../../assets/images/contents/Iip.png"
    ];

    this.test_and_profile = [
      {
        title: 'Haematology profile',
        url: 'Haematology-profile',
      },
      {
        title: 'Vitamin D',
        url: 'Vitamin-D',
      },
      {
        title: 'Diabetes Profile',
        url: 'Diabetes-Profile',
      },
      {
        title: 'Ultra Urgent PCR Travel Test - results in 3 hours',
        url: 'Ultra-Urgent-PCR-Travel-Test-results-in-3-hours',
      },
      {
        title: 'Urgent PCR Travel Test - same day results',
        url: 'Urgent-PCR-Travel-Test-same-day-results',
      },
      {
        title: 'Covid-19 Antigen LFT Test',
        url: 'Covid-19-Antigen-LFT-Test',
      },
      {
        title: 'Wellness Queen',
        url: 'wellness-queen',
      },
      {
        title: 'Wellness King',
        url: 'wellness-king',
      },
      {
        title: 'Advanced Immunity',
        url: 'advanced-immunity',
      },
    ]
  }

  get f(): { [key: string]: AbstractControl } { return this.loginForm.controls; }

  viewAll(nav) {
    if (nav === 'diagnostics') {
      this.router.navigateByUrl(`diagnostics/pathology-test-profiles`);
    }
    if (nav === 'covid test') {
      this.router.navigateByUrl(`diagnostics/covid-test`);
    }
    if (nav === 'wellness') {

    }
    if (nav === 'imaging') {

    }
  }

  individualblog(data) {
    this.router.navigateByUrl(`/individual-blog/${data.link}`);
  }


  bookNow(type) {
    if (type === 'haematology') {
      this.router.navigateByUrl(`diagnostics/profile?test=${this.test_and_profile[0].url}`);
    }
    if (type === 'vitaminD') {
      this.router.navigateByUrl(`diagnostics/profile?test=${this.test_and_profile[1].url}`);
    }
    if (type === 'diabetes') {
      this.router.navigateByUrl(`diagnostics/profile?test=${this.test_and_profile[2].url}`);
    }
    if (type === 'ultra urgent PCR') {
      this.router.navigateByUrl(`diagnostics/profile?test=${this.test_and_profile[3].url}`);
    }
    if (type === 'urgent PCR') {
      this.router.navigateByUrl(`diagnostics/profile?test=${this.test_and_profile[4].url}`);
    }
    if (type === 'Antigen LFT') {
      this.router.navigateByUrl(`diagnostics/profile?test=${this.test_and_profile[5].url}`);
    }
    if (type === 'wellness queen') {
      this.router.navigateByUrl(`wellness/multivitamin?multivitamin=${this.test_and_profile[6].url}`);
    }
    if (type === 'wellness king') {
      this.router.navigateByUrl(`wellness/multivitamin?multivitamin=${this.test_and_profile[7].url}`);
    }
    if (type === 'advanced immunity') {
      this.router.navigateByUrl(`wellness/multivitamin?multivitamin=${this.test_and_profile[8].url}`);
    }
    if (type === 'concepto-nipt-home-service') {
      this.router.navigateByUrl('nipt/nipt-home-service');
    }
    if (type === 'concepto-nipt-plus') {
      this.router.navigateByUrl('nipt/nipt-plus');
    }
    if (type === 'concepto-nipt-advance') {
      this.router.navigateByUrl('nipt/nipt-advance');
    }
    if (type === 'concepto-nipt-basic') {
      this.router.navigateByUrl('nipt/nipt');
    }
    if (type === 'early-gender-test') {
      this.router.navigateByUrl('blue-pink');
    }
    if (type === 'Anti-wrinkle') {
      window.open(
        'https://concepto-aesthetics.mytreatwell.co.uk/',
        '_blank' // <- This is what makes it open in a new window.
      );
      // this.router.navigateByUrl('aesthetics/offerings?product=Anti-wrinkle');
    }
    if (type === 'Fillers') {
      window.open(
        'https://concepto-aesthetics.mytreatwell.co.uk/',
        '_blank' // <- This is what makes it open in a new window.
      );
      // this.router.navigateByUrl('aesthetics/offerings?product=Fillers');
    }
    if (type === 'Beauty Treatments') {
      window.open(
        'https://concepto-aesthetics.mytreatwell.co.uk/',
        '_blank' // <- This is what makes it open in a new window.
      );
      // this.router.navigateByUrl('aesthetics/offerings?product=Beauty-Treatments');
    }
    if (type === 'concepto-gbs') {
      this.router.navigateByUrl('group-b-strep/individual?service=GBS-home-Test');
    }

  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    else {
      let body = {
        email: this.loginForm.controls.email.value,
        date: new Date()
      }
      this.generalService.submitNewsletterSubscribe(body).subscribe(async (res) => {
        this.subscribe = 'Subscribed';
        this.subscribed = true;
        this.email = null;
        this.disabled = true;
      })
    }
  }

  navigateTo(input) {
    if (input === 'nipt') {
      this.router.navigateByUrl('nipt');
    } else if (input === 'gbs') {
      this.router.navigateByUrl('group-b-strep');
    } else if (input === 'BlueorPink') {
      window.open(
        'https://blueorpink.co.uk/product/early-gender-test?utm_source=concepto&utm_medium=referal',
      );
    }
  }
}
